$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.optionFieldContainer{
  // display: flex;
  // flex-direction: column;
  // position: relative;
}
.optionFieldContainer .optionFieldInnerContainer{
  display: flex;
  gap: 1rem;
}
.optionFieldContainer .optionFieldInnerContainer .dragIconOuter{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
}
.optionFieldContainer .optionFieldInnerContainer .optionFieldInputContainer{
  width: calc(100%);
}