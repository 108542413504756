$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.queryGenerator {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height:4rem;
  gap:1rem;
}

.queryGenerator .queryHistoryButton {
    background-color: $blue-light-2-color;
    color: $blue-color;

    transition: 100ms ease-in-out;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    cursor: pointer;
    font-size: 1.4rem;
    &:hover{
      color: $white-color;
    background-color: $blue-color;
    }
}
// .queryGenerator .useCurrentCodeTooggle {
//     height: 3rem;
//     width: 3rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.queryGenerator input {
  flex: 1;
  margin-right: 10px;
}
.queryGenerator .queryInput{
  width: calc(100% - 8rem -  3rem - 2rem);
  // width: calc(100% - 8rem -  3rem - 3rem - 3rem);
  height: 3.5rem;
  position: relative;
  .queryInputInner{
      position: absolute;
      width: 100%;
      bottom: 0;
  }
}
.queryGenerator .generateButton{
  width: 8rem;
}