$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.MyCalendarComponent{
  height: 100%;
  display: flex;
  gap: 1rem;
}
.MyCalendarComponent .calendarComponentOuter{
  height: 100%;
  width: calc(100% - 20rem - 1rem);
}
.MyCalendarComponent .filterSelectionOuter{
  height: 100%;
  width: 20rem;
}
.MyCalendarComponent .filterSelectionOuter .filterSelectionheading{
  height: 2rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  // align-items: center;
  // flex-direction: column;
}
.MyCalendarComponent .filterSelectionOuter .filterSelectionDropDown {
  height: 4rem;
}
.MyCalendarComponent .filterSelectionOuter .filterSelectionContainer{
  height: calc(100% - 2rem - 4rem - 1rem - 1rem);
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.MyCalendarComponent .filterSelectionOuter .filterSelectionContainer .filterSelectionElem{
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  padding: .5rem .5rem;
  // border: 1px solid $gray-light-2-color;
  // border-bottom: 1px solid $gray-light-3-color;
  border-radius: 6px;
  transition: 200ms ease-in-out;
  &:hover{
    background-color: $gray-light-3-color;
  // border: 1px solid $gray-light-1-color;

  }
}
.MyCalendarComponent .calendarComponentInner{
  height: 100%;
}
.MyCalendarComponent .calendarComponentInner .calendarComponentHeading{
  height: 3rem;
  display: flex;
  gap: 1rem;
}
.MyCalendarComponent .calendarComponentInner .calendarComponentHeading .refetchButton{
  cursor: pointer;
   .refetchLoading{
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    animation:spin 4s linear infinite;
  }
}

@keyframes spin { 
  100% { 
      transform:rotate(360deg); 
  } 
}
.MyCalendarComponent .calendarComponentInner .mainCalendarComponentInner{
  height: calc(100% - 3rem - 1rem);
}