$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


// Buttons
.CalendarView .primaryButton{
    border: none;
    outline: none;
    background: $blue-color;
    color: $white-color;
    font-weight: 600;
    font-size: .9rem;
    border-radius: 6px;
    height: 3rem;
    padding: 0.5rem 1rem;
    transition: all 200ms ease-in-out;
}
.CalendarView .secondaryButtonColor{
    background: $blue-light-2-color;
    color: $blue-color;
    &:hover{
        color: $white-color;
    }
    &:disabled{
        color: $white-color;
        &:hover{
            background: $secondary-color;
        }
    }

}

.CalendarView .primaryButton.secondaryButton:disabled,
.CalendarView .primaryButton:disabled{
    background: $secondary-color;
}
.CalendarView .primaryButton:hover{
    background: #0091e4;
}
.CalendarView .primaryButton:active{
    background: #007cc4;
}
// removeButton
.CalendarView .primaryButton.removeButton{
    background: $red-1-color;
}
.CalendarView .primaryButton.removeButton:hover{
    background: $red-2-color;
}
.CalendarView .primaryButton.removeButton:active{
    background: $red-3-color;
}

.CalendarView .primaryColor{
    background: $blue-color;
    transition: 200ms ease-in-out;
}
.CalendarView .primaryColor:hover{
    background: #0091e4;
}
.CalendarView .primaryColor:active{
    background: #007cc4;
}

.CalendarView .secondaryColor{
    background: $secondary-color;
    transition: 200ms ease-in-out;
}
.CalendarView .secondaryColor:hover{
    background: $red-1-color;
}
.CalendarView .secondaryColor:active{
    background: $red-1-color;
}

.CalendarView .titleDiv{
    font-weight: 500;
}

.MainLoaderInner div {
  position: absolute;
  animation: MainLoaderInner 1s linear infinite;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  box-shadow: 0 3.6px 0 0 #262323;
  transform-origin: 72px 73.8px;
}
// .MainLoader {
//   width: 200px;
//   height: 200px;
//   display: inline-block;
//   overflow: hidden;
//   background: rgba(255, 255, 255, 0);
// }
// .MainLoaderInner {
//   width: 100%;
//   height: 100%;
//   position: relative;
//   transform: translateZ(0) scale(1);
//   backface-visibility: hidden;
//   transform-origin: 0 0; /* see note above */
// }
// .MainLoaderInner div { box-sizing: content-box; }

// @keyframes MainLoaderInner {
//     0% { transform: rotate(0deg) }
//     50% { transform: rotate(180deg) }
//     100% { transform: rotate(360deg) }
//   }
// Buttons
.CalendarView .primaryButtonFlexDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0.25rem;
    gap: 1rem;
    height: 3rem;
}
.CalendarView .primaryButtonSmall{
    border: none;
    outline: none;
    background: $blue-color;
    color: $white-color;
    padding: 0.2rem 1rem;
    font-size: .9rem;
    border-radius: 6px;
    transition: all 200ms ease-in-out;
}
.CalendarView .primaryButtonSmall:disabled{
    background: $black-hover-color;
}
.CalendarView .primaryButtonSmall:disabled:hover{
    background: $black-hover-color;
}
.CalendarView .primaryButtonSmall:hover{
    background: #0091e4;
}
.CalendarView .primaryButtonSmall:active{
    background: #007cc4;
}
.CalendarView .primaryInput{
    width: 100%;
    padding:  .5rem .75rem;
    background: transparent;
    background: $gray-light-3-color;
    outline: none;
    border: none;
    border-radius: 6px;
    margin: 0.5rem 0;
}
.CalendarView .linkButton{
    color: $blue-color;
    background-color: transparent;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    padding: 0;
    cursor: pointer;
}
.CalendarView .linkButton:hover{
    text-decoration: underline;
}
