$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;


.pickColorSettings .filterValuesInputContainer{
  display: grid;
  grid-template-columns: 1fr 4rem;
  align-items: center;
  gap: 1rem;
}
.pickColorSettings .filterValuesInputContainer .addButton{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
  .pickColorSettings .filterValuesOuterContainer{
  display:flex;
  flex-direction: column;
  padding: 1rem;
}
.pickColorSettings .filterValuesOuterContainer .filterValuesContainer{
  display: grid;
  grid-template-columns: 2rem 10rem 1fr 2rem;
  align-items: center;
  padding: .5rem 1rem;
  border-radius: 6px;
  border: 1px solid $gray-light-3-color;
}
.pickColorSettings .filterValuesOuterContainer .filterValuesContainer .filterValuesTitle{
  font-weight: 500;
}
.pickColorSettings .filterValuesOuterContainer .filterValuesContainer .filterColorThemeSelector{
  height: 2rem;
  width: 2rem;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pickColorSettings .filterValuesOuterContainer .filterValuesContainer .filterValuesRemove{
  height: 2rem;
  width: 2rem;
  border-radius: 6px;
  background-color: $gray-light-1-color;
  cursor: pointer;
  transition: 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $secondary-color;
  &:hover{
    background-color: $blue-color;
    color: $white-color;
  }
}
.pickColorSettings .colorThemeContainer{
 display: grid;
 gap: .25rem;
 grid-template-columns: 1fr 1fr;
 position: absolute;
 top: -.5rem;
 left: 2.25rem;
 background-color: $white-color;
 padding: .25rem;
 border: 1px solid $gray-light-2-color;
 border-radius: 6px;
 
}
.pickColorSettings .colorThemeElem{
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: 100ms ease-in-out;
  &:hover{
    box-shadow:  0 0 0 1px $black-hover-color;
   }
}