$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;





.addSelectedColumnContainer{
  display: flex;
  flex-direction: column;
  height: 60vh;
}

.addSelectedColumnContainer .addTeamsContainerElem{
  display: flex;
  gap: 1rem;
  cursor: pointer;
  user-select: none;
  transition: 300ms ease-in-out;
  border-radius: 6px;
  padding: .5rem 1rem;
 &:hover{
  box-shadow: inset 0 0 0 1px $gray-light-1-color;
 }
}
.addSelectedColumnContainer .descriptionContainer {
  text-align: center;
  color: $secondary-color;
  // margin-top: -.5rem;
  margin-bottom: .5rem;
}
.addSelectedColumnContainer .searchBox {
  height: 3rem;
  padding: 0 1rem;
}
.addSelectedColumnContainer .addTeamsInnerContainer {
  height: calc(100% - 3rem);
  overflow: auto;
}
.addSelectedColumnContainer .addTeamsContainerElemCheckBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addSelectedColumnContainer .addTeamsContainerElemDetails .addTeamsContainerElemName{
  font-weight: 500;
}
.addSelectedColumnContainer .addTeamsContainerElemDetails .addTeamsContainerElemDescription{
  color: $secondary-color;
}