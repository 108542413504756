$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;


.userOuterContainer {
  padding: 1.5rem;
  // border-radius: 6px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  // background-color: $gray-light-3-color;
  border: 1px solid $gray-light-3-color;
  margin: 1rem;

  padding: 1rem;
  margin: 0;
  border: none;
  margin-top: 1rem;
  border-top: 1px solid $gray-light-2-color;

}
.userOuterContainer .checkBoxOuterContainer {
  display: flex;
  // justify-content: center;
  gap: 1rem;
}
.userOuterContainer .checkBoxInnerContainer {
  width: 10rem;
  display: flex;
  // justify-content: center;
  gap: 1rem;
  align-items: center;
}
.userOuterContainer .idFieldOuterContainer {
  width: calc(100% - 10rem - 10rem - 2rem);
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.userOuterContainer .idFieldInnerLable {
  width: 6rem;
  display: flex;
  // justify-content: center;
  gap: 1rem;
  align-items: center;
}
.userOuterContainer .idFieldInnerContainer {
  width: calc(100% - 6rem - 1rem);
  display: flex;
  // justify-content: center;
  gap: 1rem;
  align-items: center;
}
.userOuterContainer .addValidationContainer {
  border: 1px solid $gray-light-1-color;
  border-radius: 6px;
  padding: 1rem;
}
.userOuterContainer .valueFieldOuterContainer {
  width: calc(100% - 1rem - 2rem);
  border: 1px solid $gray-light-3-color;
  border-radius: 6px;
  padding: 1rem;
  padding-top: 0;
  padding-right: 2rem;
  margin-top: .5rem;
  margin-left: 2rem;
  display: flex;
  // justify-content: center;
  gap: 1rem;
  align-items: center;
}