$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;




.modalFullscreen .modal-dialog {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.modalFullscreen .modal-content {
  width: 100vw;
  height: 100vh;
  border: none;
  border-radius: 0;
}

.ComponentSettingWrapper .header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 0 1rem;
  height: 4rem;
}

.ComponentSettingWrapper .body {
  padding: .5rem;
  height: calc(100% - 4rem);
}

.headerContent {
  width: 100%;
}

.headerContent .headerTitles{
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.5rem;
}
.headerTitles h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.headerTitles h2 {
  margin: 0;
  font-size: 1rem;
  color: #6c757d;
}

.headerButtons {
  display: flex;
  align-items: center;
}

.headerButtons .btn {
  margin-left: 5px;
  padding: 2px 10px;
}

.customTabs  {
margin-left: .5rem;
}
.customTabs .nav-link {
  color: $blue-color;
}

.customTabs .nav-link.active {
  font-weight: bold;
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
  cursor: pointer;
}

.tabContent {
  padding: 5px 20px;
  border: 1px solid #dee2e6;
  border-top: none;
  height: calc(100vh - 56px - 56px); /* Subtract header and tab height */
  height: calc(100vh - 50px - 56px); /* Subtract header and tab height */
  overflow-y: auto;
  border-radius: 6px;
}

.screenGroupSettings {
  display: flex;
  height: 100%;
}

.screenGroupList {
  /* flex: 1; */
  width: 20rem;
  border-right: 1px solid #dee2e6;
}

.screenGroupListContainer {
  padding: 10px;
}

.screenGroupListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  font-size: 1.25rem;
  line-height: 1;
  cursor: pointer;
}

.screenGroupSettingContainer {
  width: calc(100% - 20rem);
  padding: 20px;

}
.screenSettingsButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.screenGroupSetting {
  flex: 2;
  border-right: 1px solid #dee2e6;
  /* padding-bottom: 0; */
}

.screenSettings {
  /* flex: 3; */
  height: calc(100% - 6rem);
  overflow: auto;
  /* padding: 20px; */
}
.screenSettingsDraggableWrapperContainer {
  /* flex: 3; */
  height: calc(100% - 4rem);
  overflow: auto;
  /* padding: 20px; */
}
.screenSettingsDraggableHeaderInnerDiv {
width: calc(100% - 2rem);
display: flex;
justify-content: space-between;
align-items: center;
  /* flex: 3; */
  /* height: calc(100% - 4rem);
  overflow: auto; */
  /* padding: 20px; */
}


.draggableItem {
  display: flex;
  align-items: center;
}

.dragIcon {
  margin-right: 10px;
  cursor: grab;
}

.screenSettingsContainer {
  padding: 10px;
  height: 100%;
}

.componentContainer {
  padding: 10px;
  border: 1px solid #dee2e6;
  margin-bottom: 10px;
  border-radius: 4px;
}



.PDFTemplateWrapper .createTemplate .nextButtonDiv {
  display: flex;
  justify-content: flex-end;
}
.tablesSelectionOuterContainer {
  // margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  color: $primary-color;
}
.tablesSelectionOuterContainer .tablesSelectionTitle {
  // display: flex;
  // flex-direction: column;
  // gap: .5rem;
  font-size: 1.2rem;
  font-weight: 500;
}
.tablesSelectionOuterContainer .tablesSelectionContainer  {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.tablesSelectionOuterContainer .tablesSelectionContainer .columnSelectionDiv {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  // justify-content: space-between;
  border-radius: 6px;
  padding: 1rem;
  // height: 100%;
  border-bottom: 1px solid $gray-light-3-color;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 200ms ease-in-out;
  cursor: pointer;
  // &:nth-last-child(1) {
  //   border: none;
  // }
  &:hover {
    background-color: $gray-light-3-color;
  }
  &.columnSelectionDivDisable {
    cursor: auto;
    &:hover {
      // background-color: $white-color;
      background-color: $blue-light-2-color;
    }
  }
}

.saveButton {
  margin-top: 20px;
}

.testButton {
  margin-left: 10px;
}

.actionButtonForm .form-group {
  margin-right: 10px;
}
.componentDeleteButton{
  width: 100%;
  margin-left: auto;
}
.screenSettings .screenSettingsContainer .deleteButtonSecondary{
  background-color: $secondary-color;
}
