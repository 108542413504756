$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;

.RelatedFieldsOuterContainer {
  color: $primary-color;
  cursor: auto;
  user-select: auto;
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer {
  width: 60vw;
  height: 60vh;
  display: flex;
  gap: 1rem;
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsLeftContainer {
  width: 20rem;
  height: 100%;
  border-radius: 6px;
  border: solid 1px $gray-light-1-color;
  padding: 1rem;
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsLeftContainer .relatedFieldsAddContainer{
  height: 4rem;
  display: flex;
  flex-direction: column;
}


.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsLeftContainer .relatedFieldsListContainer{
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  gap: .5rem;
  overflow: auto;
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsLeftContainer .relatedFieldsListContainer .relatedFieldsListElem{
  padding: .75rem 1rem;
  border-radius: 6px;
  border: 1px solid $gray-light-1-color;
  cursor: pointer;
  transition: 100ms ease-in-out;
  &.relatedFieldsListElemSelected{
    background-color: $blue-light-2-color;
    // border: 1px solid $blue-light-1-color;
  }
  &:hover{
    background-color: $blue-light-2-color;
    border: 1px solid $blue-light-1-color;

  }
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsRightContainer {
  width: calc(100% - 20rem - 1rem);
  height: 100%;
  border-radius: 6px;
  padding: 1rem;
  border: solid 1px $gray-light-1-color;
  overflow: auto;
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsRightContainer .chooserelatedFieldContainer{

}

.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsRightContainer .chooserelatedFieldContainer .dropDownOuterContainer{
  display: flex;
  gap: .5rem;
  align-items: center;
  .dropDownContainer{
    width: 100% ;
  }
  &.dropDownOuterContainerIsRemove{
    .dropDownContainer{
    width: calc(100% - 2rem - .5rem);
  }
  }
  .removeButton{
    margin: .5rem 0;
    font-size: 1.5rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue-light-2-color;
    border-radius: 6px;
    transition: 100ms ease-in-out;
    cursor: pointer;
    &:hover{
      background-color: $blue-color;
      color: $white-color;
    }
  }
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsRightContainer .relatedFieldButtonContainer{
  display: flex;
  gap: .5rem;
  justify-content: flex-end;
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsRightContainer .relatedFieldsColumnOuterContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsRightContainer .relatedFieldsChooseColumnTitle{
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // gap: 1rem;
  font-weight: 500;
}
.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsRightContainer .relatedFieldsColumnInnerContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.RelatedFieldsOuterContainer .relatedFieldsInnerContainer .relatedFieldsRightContainer .relatedFieldsColumnInnerContainer .relatedFieldsColumnElem{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 6px;
  padding: 1rem;
  border: 1px solid $gray-light-1-color;
  transition: 200ms ease-in-out;
  cursor: pointer;
  &:hover{
    background-color: $gray-light-3-color;
  }
}