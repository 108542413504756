$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.KanbanComp {
  height: 100%;
  // height: calc(100% - 5rem);
}

.kanbanCompHeader {
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  color: $secondary-color;
}
.kanbanCompHeader .limitContainer {
width: 13rem;
}
.kanbanCompHeader .offsetContainer {
  user-select: none;
  // height: 4rem;
  display: flex;
  // justify-content: flex-end;
  // gap: 1rem;
  align-items: center;
}

.KanbanComp .kanbanCardOuterContainer {
  display: flex;
}
.KanbanComp .kanbanCardContainer {
  margin: 0px 1rem;
  // padding: .75rem;
  width: 20rem;
}
.KanbanComp .kanbanCardColumn {
  background-color: $gray-light-3-color;
  border: 1px solid $gray-light-1-color;
  padding: 1rem;
  border-radius: 6px;
}
.KanbanComp .kanbanCardTask {
  background-color: $white-color;
  border: 1px solid $gray-light-1-color;
  padding: .75rem;
  border-radius: 6px;
  margin-bottom: 1rem;
}


// Buttons
.KanbanComp .primaryButton{
  border: none;
  outline: none;
  background: $blue-color;
  color: $white-color;
  font-weight: 600;
  font-size: .9rem;
  border-radius: 6px;
  height: 3rem;
  padding: 0.5rem 1rem;
  transition: all 200ms ease-in-out;
}
.KanbanComp .secondaryButtonColor{
  background: $blue-light-2-color;
  color: $blue-color;
  &.secondaryButtonColorWithBorder{
    border: solid 1px $gray-light-1-color;
  }
  &:hover{
      color: $white-color;
  }
  &:disabled{
      color: $white-color;
      &:hover{
          background: $secondary-color;
      }
  }

}

.KanbanComp .primaryButton.secondaryButton:disabled,
.KanbanComp .primaryButton:disabled{
  background: $secondary-color;
}
.KanbanComp .primaryButton:hover{
  background: #0091e4;
}
.KanbanComp .primaryButton:active{
  background: #007cc4;
}
// removeButton
.KanbanComp .primaryButton.removeButton{
  background: $red-1-color;
}
.KanbanComp .primaryButton.removeButton:hover{
  background: $red-2-color;
}
.KanbanComp .primaryButton.removeButton:active{
  background: $red-3-color;
}

.KanbanComp .primaryColor{
  background: $blue-color;
  transition: 200ms ease-in-out;
}
.KanbanComp .primaryColor:hover{
  background: #0091e4;
}
.KanbanComp .primaryColor:active{
  background: #007cc4;
}

.KanbanComp .secondaryColor{
  background: $secondary-color;
  transition: 200ms ease-in-out;
}
.KanbanComp .secondaryColor:hover{
  background: $red-1-color;
}
.KanbanComp .secondaryColor:active{
  background: $red-1-color;
}
