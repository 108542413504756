$white-color: white;
$black-color: #242424;

$Light-Blue-1-Color: #c2daff;
$Light-Blue-2-Color: #aec8f0;

$primary-color: #3f4254;
$secondary-color: #7e8299;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$black-hover-color: #00000025;
$red-1-color: #d92232;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.queryGenerator .queryInput {
  color: $white-color;
    background-color: $blue-color;
    transition: 100ms ease-in-out;
    height: 3.5rem;
    width: 3.5rem;
    &:hover{
      filter: brightness(0.9);
    }
}
.queryGenerator input {
  flex: 1;
  margin-right: 10px;
}
.queryGenerator .queryInput{

  width: calc(100% - 8rem -  3rem - 2rem);
}

.queryGenerator .generateButton{
  width: 8rem;
}
.PopUpDivChild.AIQueryArea .PopUpInnerContaier {
  max-width: 80%;
  max-height: 80%;
  height: auto;
  width: auto;
  min-width: 70%;
  // max-height: 80%;
  min-height: auto;
}
.AIQueryAreaContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}


.AIQueryAreaContainer .editorArea {
  background-color: $primary-color;
  color: $white-color;
  border-radius: 8px;
  padding: 0.5rem;
  transition: 200ms ease-in-out;
}

.AIQueryAreaContainer .editorArea .editorTitle{
  display: flex;
  cursor: pointer;
  padding-top: .15rem;
  padding-bottom: .15rem;
  margin-bottom: .2rem;
  border-radius: 6px;
  transition: 200ms ease-in-out;
}
.AIQueryAreaContainer .editorArea .editorTitle:hover{
  background-color: $secondary-color;
}
.AIQueryAreaContainer .editorArea .copyQueryToClipBoardIcon{
  margin-left: auto;
  opacity: 0;
}
.AIQueryAreaContainer .editorArea:hover .copyQueryToClipBoardIcon {
  opacity: 1;
}
.AIQueryAreaContainer .editorArea.statementEditor {
  height: calc(100% - 12rem);
}
.AIQueryAreaContainer .editorArea.statementEditor .mainEditorArea{
  height: calc(100% - 1.5rem);
  max-height: none;
}
.AIQueryAreaContainer .editorArea .editorTitle {
  // margin-bottom: 0.25rem;
  font-size: 0.8rem;
  // margin-left: 0.5rem;
  padding: .25rem .5rem;
  padding-top: 0;
}
.AIQueryAreaContainer .editorArea .mainEditorArea {
  background-color: $black-color;
  color: $white-color;
  border-radius: 6px;
  overflow: auto;
  // height: 15rem;
  min-height: 5rem;
  max-height: 35rem;
  height: 45vh;
  font-size: 0.95rem;
  outline: none;
  transition: 200ms ease-in-out;
}
.AIQueryAreaContainer .editorArea.outputEditor {
    margin-top: auto;
  // background-color: $red-3-color;
  .mainEditorArea {
    height: auto;
    max-height: 10rem;
  }
  &.outputError {
    // .mainEditorArea {
    //   color: $red-1-color;
    // }
    &.querySame {
      background-color: $red-3-color;
    }
  }
  &.outputSuccess {
    // .mainEditorArea {
    //   color: $green-1-color;
    // }
    &.querySame {
      background-color: $green-2-color;
    }
  }
}


.AIQueryAreaContainer .editorArea .mainEditorArea textarea {
  outline: none;
}
.AIQueryAreaContainer .editorArea .mainEditorArea:focus-within {
  box-shadow: 0 0 2px 0 $Light-Blue-2-Color;
}