$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

.textareaScrollable {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: $primary-color $gray-light-1-color; /* Scroll thumb and track colors */
}

.textareaScrollable::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.textareaScrollable::-webkit-scrollbar-thumb {
  background-color: $primary-color; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.textareaScrollable::-webkit-scrollbar-thumb:hover {
  background-color: $primary-color; /* Darker shade on hover */
}

.textareaScrollable::-webkit-scrollbar-track {
  background-color: $gray-light-1-color; /* Scrollbar track color */
  border-radius: 10px; /* Rounded corners for the track */
}