$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.TemplateWrapper {
  height: 100%;
  width: 100%;
}
.TemplateWrapper .templateWrapperHeaderContainer {
  height: 4rem;
  display: flex;
  justify-content: flex-end;
}
.TemplateWrapper .templateWrapperCardContainer {
  height: calc(100% - 4rem);
  width: 100%;
  padding: 1rem;
  background-color: $gray-light-3-color;
  border-radius: 6px;
}
.TemplateWrapper .templatesCard {
  height: 15rem;
  width: 10rem;
  border-radius: 6px;
  border: 1px solid $gray-light-1-color;
}
.TemplateWrapper .templatesCard.pdfTemplateAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: $secondary-color;
  transition: 100ms ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: $gray-light-3-color;
    color: $blue-color;
  }
}
.TemplateWrapper .createTemplate {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.TemplateWrapper .createTemplate .createTemplateHeader {
  height: 3rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.TemplateWrapper .createTemplate .createTemplateHeader .buttonContainer {
  display: flex;
  gap: 1rem;
}

.TemplateWrapper .createTemplate .createTemplateHeader .backButton {
  background-color: $gray-light-2-color;
  color: $secondary-color;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  &:hover {
    background-color: $blue-color;
    color: $white-color;
  }
}

.TemplateWrapper .createTemplate .createTemplateContent {
  height: calc(100% - 3rem - 1rem );
  width: 100%;
}
.TemplateWrapper .createTemplate .createTemplateContent .createTemplateFormOuter {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.TemplateWrapper .createTemplate .createTemplateContent .createTemplateForm {
  height: 100%;
  overflow: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.TemplateWrapper .createTemplate .nextButtonDiv {
  display: flex;
  justify-content: flex-end;
}
.TemplateWrapper .createTemplate .tablesSelectionTitle {
  // display: flex;
  // flex-direction: column;
  // gap: .5rem;
  font-size: 1.2rem;
  font-weight: 500;
}
.TemplateWrapper .createTemplate .tablesSelectionContainer  {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.TemplateWrapper .createTemplate .tablesSelectionContainer .columnSelectionDiv {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  // justify-content: space-between;
  border-radius: 6px;
  padding: 1rem;
  // height: 100%;
  border-bottom: 1px solid $gray-light-3-color;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 200ms ease-in-out;
  cursor: pointer;
  // &:nth-last-child(1) {
  //   border: none;
  // }
  &:hover {
    background-color: $gray-light-3-color;
  }
}

.TemplateWrapper
  .createTemplate
  .createTemplateContent
  .createTemplateForm
  .createTemplateFormTitle {
  font-weight: 500;
  font-size: 1.2rem;
}
.TemplateWrapper
  .createTemplate
  .createTemplateContent
  .createTemplateForm
  .createTemplateFormHeader {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}
.selectComponentContainer {
  border-radius: 6px;
  border: solid 1px $gray-light-1-color;
  padding: 0.5rem;
  margin: 0.5rem;
}
.PDFTemplateOuterContainer {
  display: flex;
  height: 100%;
  gap: 1rem;
  flex-direction: column;
}

.PDFTemplateOuterContainer .PDFTemplateContainer {
  display: flex;
  height: calc(100% - 4rem - 1rem);
  gap: 1rem;
}
.PDFTemplateOuterContainer .queryGenerator {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  height:4rem;
  gap:1rem;
}

.queryGenerator .queryHistoryButton {
  background-color: $blue-light-2-color;
    color: $blue-color;

    transition: 100ms ease-in-out;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    cursor: pointer;
    font-size: 1.4rem;
    &:hover{
      color: $white-color;
    background-color: $blue-color;
    }
}
.PDFTemplateOuterContainer .queryGenerator input {
  flex: 1;
  margin-right: 10px;
}
.PDFTemplateOuterContainer .queryGenerator .queryInput{
  width: calc(100% - 10rem -  3rem  - 2rem);
}
.PDFTemplateOuterContainer .queryGenerator .generateButton{
  width: 10rem;
}
.PDFTemplateContainer .templateLeftContainer {
  width: calc(50% - 0.5rem);
  height: 100%;
  overflow: auto;
  border-radius: 6px;
}
.PDFTemplateContainer .templateLeftContainer .codeContainerHeader {
  display: flex;
  height: 2rem;
  margin-left: 0.5rem;
}

.PDFTemplateContainer .templateLeftContainer .codeContainerHeader .codeContainerHeaderOption {
  display: flex;
  gap: .5rem;
  border-radius: 6px 6px 0 0;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  color: $primary-color;
  cursor: pointer;
  // transition: 200ms ease-in-out;
  border: solid 1px transparent;
  &:hover {
    border: solid 1px $gray-light-3-color;
  }
  &.activeContainerTab {
    border: solid 1px $gray-light-1-color;
    border-bottom: none;
    color: $blue-color;
  }
}
.PDFTemplateContainer .templateLeftContainer .codeContainerHeader .codeContainerHeaderOption .outputColorIndicator{
  width: 1rem;
  height: 1rem;
  background-color: $gray-light-1-color;
  &.normalColorClassIndicator{
  background-color: $gray-light-1-color;
  }
  &.errorColorClassIndicator{
  background-color: $red-1-color;
}
  &.successColorClassIndicator{
  background-color: $green-1-color;
  }
  border-radius: 1rem;
}
.PDFTemplateContainer .templateLeftContainer .codeContainerHeader .resetCodeButton{
  margin-top: auto;
  margin-right: .25rem;
  cursor: pointer;
  height: auto;
  border: solid 1px $gray-light-3-color;
  border-bottom: none;
  padding: 0 1rem;
  border-radius: 6px 6px 0 0;
  height: 2rem;
  display: flex;
  align-items: center;
  transition: 100ms ease-in-out;

  // border: solid 1px $gray-light-1-color;
  border-bottom: none;
  color: $red-2-color;
  color: $secondary-color;
  // background-color: $blue-light-2-color;

  &:hover{
  border: solid 1px $gray-light-1-color;
  border-bottom: none;
  // color: $white-color;
  background-color: $blue-color;
  background-color: $gray-light-3-color;
  color: $red-2-color;
}

}
.PDFTemplateContainer .templateLeftContainer .codeContainerHeader .runCodeButton{
  margin-top: auto;
  margin-right: .25rem;
  cursor: pointer;
  height: auto;
  border: solid 1px $gray-light-3-color;
  border-bottom: none;
  padding: 0 1rem;
  border-radius: 6px 6px 0 0;
  height: 2rem;
  display: flex;
  align-items: center;
  transition: 100ms ease-in-out;

  border: solid 1px $gray-light-1-color;
  border-bottom: none;
  color: $blue-color;
  background-color: $blue-light-2-color;

  &:hover{
  border: solid 1px $gray-light-1-color;
  border-bottom: none;
  color: $white-color;
  background-color: $blue-color;
  background-color: $blue-color;
  }
}
.PDFTemplateContainer .templateLeftContainer .codeContainerHeader .MarginLeftAuto{
  margin-left: auto;
}
.PDFTemplateContainer .templateLeftContainer .codeContainer {
  height: calc(100% - 2rem);
  border: solid 1px $gray-light-1-color;
  border-radius: 6px;
  overflow: hidden;
}
.PDFTemplateContainer .templateRightContainer {
  width: calc(50% - 0.5rem);
  height: 100%;
  overflow: auto;
}

.PDFTemplateContainer .templateRightContainer .iframeOuter {
  height: calc(100% - 0.5rem);
  // height: 297mm;

  width: 100%;
  // width: 210mm;
  // width: 21cm;
  // height: 29.7cm;

  // border: 1px solid #ccc;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.PDFTemplateContainer .templateRightContainer .iframeInner {
  height: 100%;

  // width: 100%;
  // width: 210mm;
  // width: 21cm;
  // height: 29.7cm;

  // border: 1px solid #ccc;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.PDFTemplateContainer .templateRightContainer .iframeInnerError {
  width: 100%;
  padding: 3rem;
  height: 100%;
  border-radius: 6px;
  font-weight: 500;
  color: $red-1-color;
  overflow: auto;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.PDFTemplateContainer .templateRightContainer .iframe {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  // border: 1px solid #ccc;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.PDFTemplateContainer .pdfTempContainerOuter {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  background-color: $white-color;
}
