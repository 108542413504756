$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.aiCustomization {
  height: 100%;
  // height: calc(100% - 5rem);
}
.aiCustomization .aiCustomizationInner{
  height: calc(100% - 3.5rem);

}
.aiCustomization .aiCustomizationHeader {
  display: flex;
  gap: 1rem;
}
.aiCustomization .selectedTableContainer {
  width: 40rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
}
.aiCustomization .selectedTableContainer .selectedTableTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0rem;
  font-size: 1.25rem;
  gap: 1rem;
  width: 15rem;
}
.aiCustomization .selectedTableContainer .selectedTableDropDown {
  width: calc(100% - 15rem );
}

.aiCodeForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.editorHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  height: 3rem;
}
.aiCodeForm .aiCodeFormButonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.aiCustomizationOuter {
  height: 100%;
}

.aiCustomizationOuter .aiCustomizationInner {
  height: calc(100% - 5rem);
}


.apiTypes {
  height: 100%;
  padding: 10px;
  overflow: auto;
  /* background: #f8f9fa; */
  /* border-right: 1px solid #dee2e6; */
}

.apiTypes .actionButtonContainer{
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.actionButtonContainer .actionButtonDeleteButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .actionButtonDeleteButon{
    color: $red-1-color;
  }
  &.actionButtonDeleteButonActive{
    .actionButtonDeleteButon{
      color: $white-color;
    }
  }
}

.actionButtonContainer .addButton {
  font-size: 1rem;
}
.actionButtonContainer .addButton {
  font-size: 1rem;
}

.editorContainer {
  padding: 1rem;
  padding-bottom: 0;
  height: 100%;
}

.editorContainer .queryContainerPermissionOuterContainer{
  height: 3rem;
  width: 100%;
}
.editorContainer .queryContainerPermissionContainer{
  height: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // flex-direction: column;
  gap: 1rem;
  z-index: 3;
}
.editorContainer .queryContainerPermissionContainer .rowDataContainer{
  display: flex;
  gap: 1rem;

}
  .editorContainer .queryContainerPermissionContainer .permissionContainerDisplayTo{
  background-color: $white-color;
}


.queryContainerOuter{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.queryContainer{
  height: calc(100% - 3rem - 1rem);
  // height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.queryContainer.queryContainerNoHeader{
  height: calc(100% );
  
}
.queryContainer.queryContainerWithPermission{
  height: calc(100% - 3rem - 3rem - 2rem);
  
}

.queryContainer .queryEditorTabOuterContainer{
  height: calc(100% - 10rem - 1rem);
  
}
.queryContainer .queryEditorTabOuterContainer .queryEditorTabHeaderContainer{
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  // align-items: flex-end;
  height: 2rem;
}

.queryContainer .queryEditorTabOuterContainer .queryEditorTabContainer{
  display: flex;
  height: 2rem;
  margin-top: auto;
  margin-left: 0.5rem;
}
.queryContainer .queryEditorTabOuterContainer .queryEditorTabContainer .queryEditorTabOption {
  display: flex;
  gap: .5rem;
  border-radius: 6px 6px 0 0;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  color: $primary-color;
  cursor: pointer;
  // transition: 200ms ease-in-out;
  border: solid 1px transparent;
  &:hover {
    border: solid 1px $gray-light-3-color;
  }
  &.activeContainerTab {
    border: solid 1px $gray-light-1-color;
    border-bottom: none;
    color: $blue-color;

  }
  &.lowerResolutionTab{
    display: none;
  }
}
.queryContainer .queryEditorTabOuterContainer .queryEditorTabContainer .queryEditorTabOption .outputColorIndicator{
  width: 1rem;
  height: 1rem;
  background-color: $gray-light-1-color;
  &.normalColorClassIndicator{
  background-color: $gray-light-1-color;
  }
  &.errorColorClassIndicator{
  background-color: $red-1-color;
}
  &.successColorClassIndicator{
  background-color: $green-1-color;
  }
  border-radius: 1rem;
}
// @media screen and (max-height: 650px) {
@media screen and (max-height: 1600px) {
.queryContainer .queryEditorTabOuterContainer{
  height: 100% ;
  }
.queryContainer .queryOutputContainer{
    display: none;
  }
  .queryContainer .queryEditorTabOuterContainer .queryEditorTabContainer .queryEditorTabOption.lowerResolutionTab {
    display: flex;
  }
}
.queryContainer .queryEditorTabOuterContainer .MarginLeftAuto{
  margin-left: auto;
}
.queryContainer .queryEditorTabOuterContainer .moreOptionButton{
  margin-top: auto;
  margin-right: .25rem;
  cursor: pointer;
  height: auto;
  border: solid 1px $gray-light-3-color;
  border-bottom: none;
  padding: 0 1rem;
  border-radius: 6px 6px 0 0;
  height: 2rem;
  display: flex;
  align-items: center;
  transition: 100ms ease-in-out;
  .moreOptionButtonArrow{
    display: flex;
    justify-content: center;
    align-items: center;
  transition: 200ms ease-in-out;
  &.moreOptionOpen{
      rotate: 180deg;
    }
  }
  // border: solid 1px $gray-light-1-color;
  border-bottom: none;
  color: $red-2-color;
  color: $secondary-color;
  // background-color: $blue-light-2-color;

  &:hover{
  border: solid 1px $gray-light-1-color;
  border-bottom: none;
  // color: $white-color;
  background-color: $blue-color;
  background-color: $gray-light-3-color;
  color: $red-2-color;
  color: $blue-color;
}

}
.queryContainer .queryEditorTabOuterContainer .resetCodeButton{
  margin-top: auto;
  margin-right: .25rem;
  cursor: pointer;
  height: auto;
  border: solid 1px $gray-light-3-color;
  border-bottom: none;
  padding: 0 1rem;
  border-radius: 6px 6px 0 0;
  height: 2rem;
  display: flex;
  align-items: center;
  transition: 100ms ease-in-out;

  // border: solid 1px $gray-light-1-color;
  border-bottom: none;
  color: $red-2-color;
  color: $secondary-color;
  // background-color: $blue-light-2-color;

  &:hover{
  border: solid 1px $gray-light-1-color;
  border-bottom: none;
  // color: $white-color;
  background-color: $blue-color;
  background-color: $gray-light-3-color;
  color: $red-2-color;
}

}
.queryContainer .queryEditorTabOuterContainer .runCodeButton{
  margin-top: auto;
  margin-right: .25rem;
  cursor: pointer;
  height: auto;
  border: solid 1px $gray-light-3-color;
  border-bottom: none;
  padding: 0 1rem;
  border-radius: 6px 6px 0 0;
  height: 2rem;
  display: flex;
  align-items: center;
  transition: 100ms ease-in-out;

  border: solid 1px $gray-light-1-color;
  border-bottom: none;
  color: $blue-color;
  background-color: $blue-light-2-color;

  &:hover{
  border: solid 1px $gray-light-1-color;
  border-bottom: none;
  color: $white-color;
  background-color: $blue-color;
  background-color: $blue-color;
  }
}
.queryContainer .queryEditorTabOuterContainer .queryEditorInnerContainer{
  height: calc(100% - 2rem);

}
.queryContainer .queryEditorContainer{
  height: 100% ;
  border-radius: 6px;
  overflow: hidden;
  background-color: $primary-color;
  padding: .5rem;
}
.queryContainer .queryEditorContainer .querytitleArea{
  height: 1.5rem;
  font-size: .8rem;
  font-weight: 500;
  margin-left: .5rem;
  color: white;
}
.queryContainer .queryEditorContainer .queryEditorArea{
  height: calc(100% - 1.5rem);
  border-radius: 6px;

  overflow: hidden;
}
.queryContainer .queryOutputContainer{
  height: calc( 10rem);
  border-radius: 6px;
  overflow: hidden;
  background-color: #3f4254;
  padding: .5rem;
}
.queryContainer .queryOutputContainer .querytitleArea{
  height: 1.5rem;
  font-size: .8rem;
  font-weight: 500;
  margin-left: .5rem;
  color: $white-color;
}
.queryContainer .queryOutputContainer .queryEditorArea{
  height: calc(100% - 1.5rem);
  border-radius: 6px;
  overflow: hidden;
}
  /* .queryGenerator {
  height: calc(100% - 3rem - 10rem);
} */


.queryGenerator {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height:4rem;
  gap:1rem;
}

.queryGenerator .queryHistoryButton {
    background-color: $blue-light-2-color;
    color: $blue-color;

    transition: 100ms ease-in-out;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    cursor: pointer;
    font-size: 1.4rem;
    &:hover{
      color: $white-color;
    background-color: $blue-color;
    }
}

.queryGenerator input {
  flex: 1;
  margin-right: 10px;
}
.queryGenerator .queryInput{
  width: calc(100% - 8rem -  3rem - 2rem);
}
.queryGenerator .generateButton{
  width: 8rem;
}
.actionButtonForm {
  display: flex;
  align-items: center;
}
.actionButtonOptions{
  display: flex;
  // justify-content: center;
  align-items: center;
  margin-right: 1rem;
  gap: 1rem;
}
.actionButtonOptions .actionButtonOptionsLabel{
  margin-bottom: 0;
  text-wrap: nowrap;
}

.actionButtonForm .form-group {
  margin-right: 10px;
}



.editorContainer {
  padding: 1rem;
  padding-bottom: 0;
  height: 100%;
}


.queryContainerOuter{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.editorContainer .queryContainerPermissionOuterContainer{
  height: 3rem;
  width: 100%;
}
.editorContainer .queryContainerPermissionContainer{
  height: 3rem;
  // display: flex;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // flex-direction: column;
  gap: 1rem;
  z-index: 3;
}
.editorContainer .queryContainerPermissionContainer .permissionContainerDisplayTo{
  background-color: $white-color;
}
.queryContainerPermissionContainer .displayWhenFilterContainer{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.queryContainerPermissionContainer .displayWhenFilterContainer.FlexEnd{
  justify-content: flex-end;
}

.queryContainerPermissionContainer .filterIconContainer{
  // margin-left: auto;
  display: flex;
  align-items: center;
  // width: 8rem;
  gap: 1rem;
  position: relative;
}
.queryContainerPermissionContainer .filterIconContainer{
  position: relative;
}
.queryContainerPermissionContainer .filterIconContainer .filterNumber{
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  right: -.5rem;
  top: -.5rem;
  background-color: $blue-color;
  border: solid 1px $blue-light-2-color;
  color: $white-color;
  border-radius: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: .75rem;
}