$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


//Filter
.PopUpDiv.AppComponentFilter .mainTitleDiv {
    display: flex;
    height: 4rem;
    padding: 0 0.5rem;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
}
.PopUpDiv.AppComponentFilter .mainTitleDiv span{
    font-size: 1rem;
    color: $blue-color;
    cursor: pointer;
    user-select: none;
}
.PopUpDiv.AppComponentFilter .mainTitleDiv span:hover{
    text-decoration: underline;
}
.PopUpDiv.AppComponentFilter .filterButton{
    padding: .5rem 1rem;
}
.PopUpDiv.AppComponentFilter .filterButton .primaryButton{
    width: 100%;
}

.PopUpDiv.AppComponentFilter .primaryButton {
    border: none;
    outline: none;
    background: #00A3FF;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    border-radius: 6px;
    height: 3rem;
    padding: 0.5rem 1rem;
    transition: all 200ms ease-in-out;
}

.PopUpDiv.AppComponentFilter .secondaryColor{
    background: $secondary-color;
    transition: 200ms ease-in-out;
}
.PopUpDiv.AppComponentFilter .secondaryColor:hover{
    background: $red-1-color;
}
.PopUpDiv.AppComponentFilter .secondaryColor:active{
    background: $red-1-color;
}

.PopUpDiv.AppComponentFilter .linkButton{
    border: none;
    outline: none;
    text-decoration: underline;
    background-color: transparent;
    color: $blue-color;
    font-weight: 600;
    font-size: .9rem;
    border-radius: 6px;
    height: 3rem;
    // padding: 0.5rem 1rem;
    transition: all 200ms ease-in-out;
}
.PopUpDiv.AppComponentFilter .linkButton:hover{
    color: #0091e4;

}
.PopUpDiv.AppComponentFilter .linkButton:active{
    color: #007cc4;

}

.PopUpDiv.AppComponentFilter .primaryInput {
    width: 100%;
    padding: 0.5rem 0.75rem;
    background: transparent;
    background: #F5F8FA;
    outline: none;
    border: none;
    border-radius: 6px;
    margin: 0.5rem 0;
}

.PopUpDiv.AppComponentFilter .primaryButton {
    width: 100%;
}
//NEW
.PopUpDiv.AppComponentFilter .popUpOuterContaier .PopUpInnerContaier{
    display: flex;
    width: 45%;
    min-width: 50rem;
}


.PopUpDiv.AppComponentFilter .filterPopUpOuterContaier{
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000045;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.PopUpDiv.AppComponentFilter .filterPopUpOuterContaier .filterPopUpInnerContaier{
    background-color: $white-color;
    padding: 1rem;
    min-width: 60%;
    max-width: 80%;
    min-height: 60%;
    max-height: 80%;
    border-radius: 6px;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.PopUpDiv.AppComponentFilter .closeButton{

    background: $blue-light-1-color;
    font-weight: 600;
    margin-left: auto;
    box-sizing: border-box;
    height: 2rem;
    width: 2rem;
    display: flex;
    // box-shadow: 0px 2px 20px #0000001a;
    justify-content: center;
    color: $white-color;
    align-items: center;
    border-radius: 4rem;
    cursor: pointer;
    transition: 200ms ease-in-out;
    user-select: none;
    min-height: 2rem;
}
.PopUpDiv.AppComponentFilter .closeButton img{
    height: 75%;
}

.PopUpDiv.AppComponentFilter .popUpOuterContaier .PopUpInnerContaier{
    height: 80%;
}
.PopUpDiv.AppComponentFilter .popUpOuterContaier .filterDivContainer{
    display: flex;
    height: calc(100% - 3rem);
    overflow: auto;
    gap: 1rem;
}
$filter-section-width:20rem;
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection{
    background: $gray-light-2-color;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: $filter-section-width;
    height: 100%;
    // overflow: auto;
    gap: .5rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection{
    height: 8rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .mainTitleDiv{
    padding-left: 1rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .serachBoxOuter{
    width: 100%;
    display: flex;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .CustomDropdown{
    width: 3rem;
    display: flex;
    align-items: center;
    position: relative;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .CustomDropdown .dropDownArray{
    width: 10rem;
    top: 3rem;
    right: 1rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .searchBox{
    width: calc(100% - 3rem);
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 .5rem;
    height: 4rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .searchBox input[type=text]{
    border-radius: 6px;
    border: none;
    outline: none;
    width: 100%;
    padding: 0.5rem 1rem;
    padding-right: 2.8rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .searchBox input[type=text]::placeholder{
    font-weight: 500;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .searchBox .searchIcon{
    position: absolute;
    right: 0.8rem;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .chartFilterByType{
    height: 3.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 0 .5rem;
    padding-left: 1rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .chartFilterByType .chartFilterByTypeTitle{
    width: 8rem;
    font-weight: 500;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .staticSection .chartFilterByType .chartFilterByTypeDropDown{
    position: relative;
    width: calc( 100% - 8rem);
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .allTable{
    background: $gray-light-2-color;
    border-radius: 6px;
    padding: 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    width: $filter-section-width;
    height: calc(100% - 8rem);
    overflow: auto;
    gap: .5rem;

}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .tablesItems{
    background: $gray-light-1-color;
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .tablesItems .tablesItemTitle{
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem .5rem;
    display: flex;
    cursor: pointer;

}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .tablesItems .tablesItemTitle img{
    margin-left: auto;
    transition: 200ms ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    height: .5rem;
    padding: 0 .25rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .tablesItems.open .tablesItemTitle img{
    transform: rotate(180deg);
}


.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .tablesItems  .tablesItemList{
    max-height: 0px;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .tablesItems.open .tablesItemList{
    display: block;
    max-height: 9999em;
    transition: max-height 3s ease-in-out;

}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .filterTable{
    background: $white-color;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    height: 2.5rem;
    padding: 0.5rem .75rem;
    border-radius: 6px;
    transition: 100ms ease-in-out;
    cursor: pointer;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .filterTable .titleSection{
    width: calc(100% - 2.5rem );
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    gap: .5rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .filterTable .titleSectionIcon{
    width:  1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .filterTable .titleSectionText{
    width: calc(100% - 1.5rem  - .5rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
    .PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .filterTable .toggleSection{
    width: calc(2.5rem - .5rem);
    display: flex;
    justify-content: center;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .filterTable:hover{
    background: $gray-light-2-color;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterselection .filterTable.selected{
    background: $gray-light-2-color;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing{
    background: $gray-light-1-color;
    border-radius: 6px;
    padding: 0.25rem 0.25rem;
    width: calc(100% - #{$filter-section-width});
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems{
    background-color: $white-color;
    margin: 0.5rem 0.25rem;
    display: flex;
    flex-direction: column;
    min-height: 2.5rem;
    padding: 0.5rem 1rem;
    border-radius: 6px;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems:nth-child(1){
    margin-top: 0.5rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems:nth-last-child(1){
    margin-bottom: 0.5rem;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .Title{
    font-weight: 500;
    padding-bottom: 0.25rem;
    display: flex;
    align-items: center;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .filterIcon{
    margin-bottom: .25rem;
    margin-right: .5rem;
    font-size: 1.25rem;
    width:1.5rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .titleName{
    width: calc(100% - 3rem);
    
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .removeFilter{
    // width:1.5rem;
    // margin: 0 .25rem;
    font-size: 1.25rem;
    cursor: pointer;
    transition: 150ms ease-in-out;
    color: $secondary-color;

}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .removeFilter:hover{
    color: $secondary-color;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .removeFilter:active{
    color: $secondary-color;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .sliderDiv{
    width: 92%;
    margin: auto;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .idTypeContainer{
padding: .5rem .5rem;
margin-top: .5rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .idTypeContainerTitle{
// padding: .5rem;
font-weight: 500;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .idTypeContainerCheckBoxContainer{
display: flex;
// gap: .5rem;
margin-top: .25rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .idTypeContainerCheckBoxElem{
display: flex;
align-items: center;
padding: .5rem .75rem;
gap: .5rem;
cursor: pointer;
border-radius: 6px;
transition: 200ms ease-in-out;
&:hover{
    box-shadow: 0 0 0 1px $gray-light-3-color;
}
}


.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .numberInputDivContainer{
display: flex;
justify-content: space-between;
padding: 0 1rem;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .innerNumberInputDiv{
    position: relative;
    width: 30%;
    min-width: 10rem;
    max-width: 12rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
    }
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .innerNumberInputDiv input{
    transition: 300ms ease-in-out;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .innerNumberInputDiv .titleDiv{
    width: 2.5rem;
    // display: flex;
    // justify-content: center;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .innerNumberInputDiv .errorInfo{
    position: absolute;
    right: .25rem;
    top: .5rem;
    color: $white-color;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .primitiveValuesContainer{
    display: flex;
    gap: 1rem;
    padding: .5rem .5rem ;
}
.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .primitiveValuesContainer .primitiveElem{
    padding: .5rem 1rem;
    border-radius: 5rem;
    border:solid 1px $secondary-color;
    color: $secondary-color;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
}

.PopUpDiv.AppComponentFilter .filterDivContainer .filterEditing .containerItems .primitiveValuesContainer .primitiveElem.selectedPrimitiveElem{
    border:solid 1px $blue-color;
    color: $blue-color;
}


// calendarWrap
.PopUpDiv.AppComponentFilter .calendarWrap .editInputDiv{
    display: flex;
    gap: 1rem;
}
.PopUpDiv.AppComponentFilter .filterButton .calendarWrap input{
    // border: 1px solid #ccc;
    // border-radius: 4px;
    // width: 60%;
}
.PopUpDiv.AppComponentFilter .filterButton .calendarWrap button.primaryButton{
    width: auto;
    // width: 40%;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000038;
    height: 100vh;
    width: 100vw;
    z-index: 5;
    justify-content: center;
    display: flex;
    align-items: center;
}
.PopUpDiv.AppComponentFilter .calendarWrap .primaryButton{
    // margin: .5rem;
    height: 2.5rem;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv{
    background-color: $white-color;
    padding: 1rem;
    border-radius: 6px;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .closeButton{
    margin-bottom: 1rem;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker{
    display: flex;
    gap: .5rem;
    height: 30rem;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker .leftDivPickerOptionsContainer{
    width: 12rem;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker .leftDivPickerOptionsContainer .leftDivPickerOption{
    white-space: nowrap;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-weight: 500;
    border-bottom: solid 1px $gray-light-1-color;
    font-size: 1rem;
}

.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker .leftDivPickerOptionsContainer .leftDivPickerOption:hover{
    color: $blue-color;
    background-color: $gray-light-3-color;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker .leftDivPickerOptionsContainer .leftDivPickerOption.active{
    color: $blue-color;
    background-color: $gray-light-2-color;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker .leftDivPickerOptionsContainer .xDayDiv{
    width: 100%;
    padding: 0 1rem;
    display: flex;
    gap: 0.5rem;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker .leftDivPickerOptionsContainer .xDayDiv .titleDiv{
    width: 40%;
    display: flex;
    align-items: center;
}
.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker .leftDivPickerOptionsContainer .xDayDiv .inputDiv{
    width: 3.5rem;
}

.PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker .calendarElement{
    width: 80%;
}

.PopUpDiv.AppComponentFilter .selectAnyFilter{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PopUpDiv.AppComponentFilter .selectAnyFilter .innerBox{
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    border-radius: 6px;
    border: dashed 1px $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 1px;
}
// dashBoard
.PopUpDiv.AppComponentFilter .allTable .dashboardFilterColumnContainer{
    background: $gray-light-3-color;
    border-radius: 6px;
    padding: 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.PopUpDiv.AppComponentFilter .allTable .dashboardName{
    font-weight: 500;
    margin-left: .5rem;
    font-size: 1rem;
    margin: .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
}

// Mobile View

.PopUpDiv.AppComponentFilter  .toggleDiv{
    display: none;
    position: absolute;
    align-items: center;
    gap: 1rem;
}
.PopUpDiv.AppComponentFilter  .toggleDiv .toggleIcon{
    height: 2rem;
    width: 2rem;
    border-radius: 6px;
    background-color: $gray-light-3-color;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: toggleIcon 1s infinite ease-in-out;
}
.PopUpDiv.AppComponentFilter  .toggleDiv .toggleFilterName{
    font-weight: 500;
    font-size: 1.4rem;
}
@keyframes toggleIcon {
    0%{
        background-color: $gray-light-3-color;
    }
    100%{
        background-color: $gray-light-1-color;
    }
}
.PopUpDiv.AppComponentFilter  .toggleDiv .toggleIcon:active{
    background-color: $gray-light-2-color;
}
.PopUpDiv.AppComponentFilter  .toggleDiv .toggleIcon.open{
    background-color: $gray-light-2-color;
    animation: none;
}
.PopUpDiv.AppComponentFilter  .toggleDiv .toggleIcon.open:active{
    background-color: $gray-light-1-color;
}
@media screen and (max-width: 650px) {
    .PopUpDiv.AppComponentFilter  .toggleDiv{
        display: flex;
    }

    .PopUpDiv.AppComponentFilter .popUpOuterContaier .PopUpInnerContaier{
        min-width: auto;
        max-width: none;
        min-height: auto;
        max-height: none;
        width: 100%;
        height: 100%;
    }
    .PopUpDiv.AppComponentFilter .popUpOuterContaier .filterDivContainer .filterselection{
        display: none;
        position: absolute;
        z-index: 5;
        width: calc( 100% - 2rem);
    }
    .PopUpDiv.AppComponentFilter .popUpOuterContaier .filterDivContainer .filterselection.open{
        display: flex;
    }
    .PopUpDiv.AppComponentFilter .popUpOuterContaier .filterDivContainer .filterselection .allTable{
        width: 100%;
    }
    .PopUpDiv.AppComponentFilter .popUpOuterContaier .filterDivContainer .filterselection{
        height: calc( 100% - 4rem);
    }
    .PopUpDiv.AppComponentFilter .popUpOuterContaier .filterDivContainer .filterEditing{
        // display: none;
        width: 100%;
    }

    

  }
  
    .PopUpDiv.AppComponentFilter .calendarWrap .calendarElement.verticalCalender{
      display: inline-flex;
  }
  .PopUpDiv.AppComponentFilter .calendarWrap .calendarElement.horizontalCalender{
      display: none;
    }
    
.PopUpDiv.AppComponentFilter .calendarWrap .calendarElement.singleCalender{
display: none;
}
  @media screen and (max-width: 900px) {

  .PopUpDiv.AppComponentFilter .calendarWrap .calendarElement.verticalCalender{
    display: none;
    }
    .PopUpDiv.AppComponentFilter .calendarWrap .calendarElement.horizontalCalender{
        display: inline-flex;
    }
    .PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker{
        height: 48rem;
    }
  }
  @media screen and (max-width: 550px) {
    .PopUpDiv.AppComponentFilter .calendarWrap .calendarElement.horizontalCalender{
        display: none;
    }
    .PopUpDiv.AppComponentFilter .calendarWrap .calendarElement.singleCalender{
        display: inline-flex;
    }
    .PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker{
        height: 32rem;
    }
    .PopUpDiv.AppComponentFilter .calendarWrap .outerDatePickerDiv .innerDatePickerDiv .bottomDivPicker{
        width: 85vw;
        overflow: auto;
    }
}