$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.EditTableSettings .addNewColumnData {
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid $gray-light-1-color;
  margin-top: 1rem;
}
.EditTableSettings .accordionStyle {
  box-shadow: none;
  color: $secondary-color; 
  background-color: $gray-light-3-color;
  border-radius: 6px;
}
.EditTableSettings .accordionDetails {
  box-shadow: none;
  background-color: $white-color;
  border: 1px solid $gray-light-2-color;
  border-radius: 6px;
}

