$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;

.roleAccessContainer {
  height: 100%;
  width: 100%;
}
.roleAccessContainer .roleAccessInnerContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: calc(100% - 4rem);
}
.roleAccessContainer .roleAccessTeamSelection {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  padding: 0rem 0rem;
  font-size: 1.25rem;
  gap: 2rem;
  font-weight: 500;
}

.roleAccessTeamCrudContainer {
  width: 100%;
  height: 20rem;
  position: relative;
}
.roleAccessGridContainer {
  display: grid;
  overflow: auto;
  align-content: baseline;
  width: 100%;
  height: 100%;
  // height: calc(100% - 4rem - 2px);
  position: absolute;
  border: 1px solid $blue-light-1-color;
  border-radius: 6px;
}
.roleAccessGridContainer .cellInnerDiv {
  display: flex;
  gap: 1rem;
  align-items: center;
  // justify-content: center;
}

.roleAccessGridContainer .gridCell {
  padding: 0.5rem;
  min-width: 12rem;
  width: 100%;
  // width: max-content;
  // width: min-content;
  // width: clamp(min-content, 100%, auto);
  height: 3rem;
  display: flex;
  align-items: center;
  // border: 1px solid $borderColor;
  .editCellInputTextDiv {
    width: 100%;
  }
}
.roleAccessGridContainer .gridCell.upperColumnGridCell {
  position: sticky;
  top: 0;
  background-color: $gray-light-3-color;
  border-bottom: 1px solid $blue-light-1-color;
  border-right: 1px solid $blue-light-1-color;
  font-weight: 600;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.roleAccessGridContainer .gridCell.valueColumnGridCell {
  border-bottom: 1px solid $blue-light-1-color;
  border-right: 1px solid $blue-light-1-color;
}


.roleAccessGridContainer .gridCell .valueFilterIcon {
  font-size: 1.25rem;
  cursor: pointer;
  .valueGridDisabled {
    color: $secondary-color;
    cursor: auto;
  }
}

.roleAccessGridContainer .gridCell.valueGridDisabled {
  color: $secondary-color;
  cursor: auto;
  .valueFilterIcon{
    cursor: auto;
  }
}
.roleAccessGridContainer .gridCell.sideColumnGridCell {
  position: sticky;
  width: 18rem;
  left: 0;
  background-color: $gray-light-3-color;
  border-bottom: 1px solid $blue-light-1-color;
  border-right: 1px solid $blue-light-1-color;
  font-weight: 500;
}
.roleAccessGridContainer .gridCell.teamNameColumnGridCell {
  z-index: 4;
  font-size: 1.1rem;
}
.roleAccessContainer .columnWiseRoles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
}
.roleAccessContainer .columnWiseRoles .columnWiseRolesTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0rem;
  font-size: 1.25rem;
  gap: 1rem;
  // font-weight: 500;
  width: 25rem;
}
.roleAccessContainer .columnWiseRoles .selectedRolesDropDownContainer {
  width: calc(100% - 25rem );
}

.columnWiseRolesGridOuterContainer {
  width: 100%;
  height: calc(100% - 20rem - 10rem);
  position: relative;
}
@media screen and (max-height: 700px) {
  .roleAccessTeamCrudContainer {
    height: 12rem;
  }
  .columnWiseRolesGridOuterContainer {
    height: calc(100% - 12rem - 10rem);
  }
}
.columnWiseRolesGridInnerContainer {
  display: grid;
  overflow: auto;
  align-content: baseline;
  width: 100%;
  height: 100%;
  // height: calc(100% - 4rem - 2px);
  position: absolute;
  border: 1px solid $blue-light-1-color;
  border-radius: 6px;
}
.columnWiseRolesGridInnerContainer .cellInnerDiv {
  display: flex;
  gap: 1rem;
  align-items: center;
  // justify-content: center;
}

.columnWiseRolesGridInnerContainer .gridCell {
  padding: 0.5rem;
  min-width: 12rem;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  .editCellInputTextDiv {
    width: 100%;
  }
}
.columnWiseRolesGridInnerContainer .gridCell.upperColumnGridCell {
  position: sticky;
  top: 0;
  background-color: $gray-light-3-color;
  border-bottom: 1px solid $blue-light-1-color;
  border-right: 1px solid $blue-light-1-color;
  font-weight: 600;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.columnWiseRolesGridInnerContainer .gridCell.valueColumnGridCell {
  border-bottom: 1px solid $blue-light-1-color;
  border-right: 1px solid $blue-light-1-color;
}

.columnWiseRolesGridInnerContainer .gridCell .valueFilterIcon {
  font-size: 1.25rem;
  cursor: pointer;
  &.valueGridDisabled {
    color: $secondary-color;
    cursor: auto;
  }
}
.columnWiseRolesGridInnerContainer .gridCell.valueGridDisabled {
  color: $secondary-color;
  cursor: auto;
  .valueFilterIcon{
  cursor: auto;

  }
}
.columnWiseRolesGridInnerContainer .gridCell.sideColumnGridCell {
  position: sticky;
  width: 18rem;
  left: 0;
  background-color: $gray-light-3-color;
  border-bottom: 1px solid $blue-light-1-color;
  border-right: 1px solid $blue-light-1-color;
  font-weight: 500;
}
.columnWiseRolesGridInnerContainer .gridCell.teamNameColumnGridCell {
  z-index: 4;
  font-size: 1.1rem;
}
