$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;

.AdditionalSettings {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.AdditionalSettings .mainTableContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.AdditionalSettings .mainTableContainer .mainTableTitle{
  font-size: 1.2rem;
  font-weight: 500;
}
.AdditionalSettings .chooseConnectedTables{
  height: 3rem;  
}
.AdditionalSettings .connectedTablesContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.AdditionalSettings .selectColumnContainer{
  // height: calc(100% - 3rem - 1rem);  
  overflow: auto;
}

.AdditionalSettings .selectColumnContainer .draggableItemHeader{
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding-right: 1rem;
  gap: 1rem;
}
.AdditionalSettings .selectColumnContainer .draggableItemHeader .selectColumnsButton{
  border: 1px solid $gray-light-1-color;
}
.AdditionalSettings .marginLeftAuto{
  margin-left: auto;
}
.AdditionalSettings .selectColumnContainer .draggableItemOuter{
  padding: .5rem 1rem;
  border: 1px solid $black-hover-color;
  margin-bottom: 0px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: $white-color;
}
.AdditionalSettings .selectColumnContainer .dragIcon{
  font-size: 1.5rem;
  color: $secondary-color;
  &:hover{
    color: $blue-color;
  }
}
.AdditionalSettings .selectColumnContainer .draggableItem{
 width: calc(100% - 3rem );
 display: flex;
 align-items: center;
 justify-content: space-between;
 gap: 1rem;
 background-color: $white-color;
}

.AdditionalSettings .selectColumnContainer .draggableItemNameOuterContainer{
  display: flex;
  gap: 1rem;
} 
.AdditionalSettings .selectColumnContainer .draggableItem .draggableItemTitle{
  text-wrap: nowrap;
  margin-bottom: 0;
  width: calc(100% - 35rem);
  overflow: hidden;
  text-overflow: ellipsis;
}
.AdditionalSettings .selectColumnContainer .draggableItemDescription{
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: $white-color;
}
.AdditionalSettings .selectColumnContainer .draggableItemDescription .draggableItemDescriptionLabel{
  margin: 0;
}
.AdditionalSettings .selectColumnContainer .draggableItemDescription .draggableItemDescriptionInput{
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}
.AdditionalSettings .selectColumnContainer .draggableItem .filterIconContainer{
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}
.AdditionalSettings .selectColumnContainer .filterIconContainer{

  position: relative;
}
.AdditionalSettings .selectColumnContainer .filterIconContainer .filterNumber{
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  right: -.5rem;
  top: -.5rem;
  background-color: $blue-color;
  border: solid 1px $blue-light-2-color;
  color: $white-color;
  border-radius: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: .75rem;
}
.AdditionalSettings .selectColumnContainer .draggableItem .filterIconContainer .filterIcon{
  font-weight: 500;
  cursor: pointer;
  font-size: 1.75rem;
  color: $blue-color;
  padding: .25rem;
  &:hover{
    color: $blue-color;
  }
}
.AdditionalSettings .selectColumnContainer .screenNameOuterContainer{
  display: flex;
  gap: 1rem;
  align-items: center;
}
.AdditionalSettings .selectColumnContainer .screenNameOuterContainer .verticalLine{
  height: 3rem;
  width: 1px;

}
.AdditionalSettings .selectColumnContainer .screenNameInputContainer{
  display: flex;
  gap: 1rem;
  align-items: center;
  width: calc(50% - 1rem);
}

.AdditionalSettings .selectColumnContainer .screenNameInputContainer .screenNameInputLabel{
  text-wrap: nowrap;
  margin-bottom: 0;
}
