.MuiChip-root {
  flex-direction: row-reverse;
  padding-left: 15px !important;
}

.MuiChip-label, .MuiChip-labelMedium {
  padding-left: 5px !important;
}

.MuiChip-deleteIcon, .MuiChip-deleteIconMedium {
  margin-right: 0 !important;
}

.projects-navbar-accordion-header > .accordion-button {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cursor-pointer {
  cursor: pointer;
}