$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

/* Switch */
.AppComponentFilter .SlideSwitchToggle {
    display: inline-block;
    span {
        position: relative;        
        width: 3rem - .6rem;
        height: 1.6rem;
        float: left;
        input {
            display:none;
            &:checked + .SlideSwitchSlider { background-color: $blue-color; }
            &:checked + .SlideSwitchSlider:before { transform: translateX(1rem - .3rem ); }
            &:focus + .SlideSwitchSlider { box-shadow: 0 0 1px $blue-color; }
        }
    }
    label {
        line-height: 34px;
        margin-left: 12px;
        cursor: pointer;
    }
}
.AppComponentFilter .SlideSwitchSlider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border: 0;
    outline: none;
    background-color: $gray-light-1-color;
    transition: .4s;
    border-radius: 34px;
    cursor: pointer;
    &:before {
        position: absolute;
        content: "";
        height: 70%;
        aspect-ratio: 1;
        left: .3rem;
        bottom: 15%;
        background-color: $white-color;
        transition: .4s;
        border-radius: 50%;
    }
}
